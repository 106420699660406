export enum ActionableInsightCategory {
  UserActionableInsights = 'UserActionableInsights',
  RuntimeOptimization = 'RuntimeOptimization',
  SystemExceptions = 'SystemExceptions',
  BusinessExceptions = 'BusinessExceptions',
}

export const ActionableInsightCategoryDescription = new Map<string, string>([
  [ActionableInsightCategory.UserActionableInsights, 'Analyst\'s insights'],
  [ActionableInsightCategory.RuntimeOptimization, 'Runtime optimization'],
  [ActionableInsightCategory.SystemExceptions, 'System exceptions'],
  [ActionableInsightCategory.BusinessExceptions, 'Business exceptions'],
]);

export enum ActionableInsightStatus {
  Starred = 'Starred',
  Resolved = 'Resolved',
  Irrelevant = 'Irrelevant',
}

export enum ActionableInsightType {
  Exception = 'Exception',
  ExceptionBluePrism = 'ExceptionBluePrism',
  Outliers = 'Outliers',
  ForcedStop = 'ForcedStop',
  WaitOptimization = 'WaitOptimization',
  UnnecessaryInitializations = 'UnnecessaryInitializations',
  IdleRuns = 'IdleRuns',
  ItemDurationTrend = 'ItemDurationTrend',
  InterruptedJobs = 'InterruptedJobs',
  RpaObject = 'RpaObject',
  Rework = 'Rework',
  User = 'User',
  Other = 'Other',
}

export enum AlertConditionType {
  Lower = 'Lower',
  Equal = 'Equal',
  Greater = 'Greater',
}

export enum AlertSeverity {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export enum AlertRuleType {
  Metric = 'Metric',
  ProcessNotStarted = 'ProcessNotStarted',
  ResourceRestarted = 'ResourceRestarted',
  ResourceLoginLogout = 'ResourceLoginLogout',
  IncidentDetected = 'IncidentDetected',
}

export enum AlertTargetType {
  InApp = 'InApp',
  Email = 'Email',
}

export enum BenchmarkMetricType {
  SavedFtePerLicense = 'SavedFtePerLicense',
  ManualVsBotProcessingTimeRatio = 'ManualVsBotProcessingTimeRatio',
  ProcessesVsLicensesRatio = 'ProcessesVsLicensesRatio',
  RoiOfRpa = 'RoiOfRpa',
  CostOfSavingOneFte = 'CostOfSavingOneFte',
  FteEfficiency = 'FteEfficiency',
  TotalCostPerLicense = 'TotalCostPerLicense',
  RpaTeamEfficiency = 'RpaTeamEfficiency',
  DevelopmentEfficiency = 'DevelopmentEfficiency',
  OperationEfficiencyLicenses = 'OperationEfficiencyLicenses',
  OperationEfficiencyProcesses = 'OperationEfficiencyProcesses',
  AvgDevTimeOfSavingOneFte = 'AvgDevTimeOfSavingOneFte',
  AvgDevSpeed = 'AvgDevSpeed',
}

export enum ChangeType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum Direction {
  Above = 'Above',
  Below = 'Below',
}

export enum ExceptionSortBy {
  Count = 'Count',
  Cost = 'Cost',
}

export enum ExceptionSource {
  Tag = 'Tag',
  Message = 'Message',
}

export enum ExceptionType {
  BusinessException = 'BusinessException',
  SystemException = 'SystemException',
  UnexpectedInterruption = 'UnexpectedInterruption',
}

export enum FeatureFlags {
  Alerting = 'Alerting',
  BenchmarkingFormulas = 'BenchmarkingFormulas',
  OrchestratorQuarantine = 'OrchestratorQuarantine',
  OrchestratorRecovery = 'OrchestratorRecovery',
  UserDocumentation = 'UserDocumentation',
  BenchmarkingSettings = 'BenchmarkingSettings',
}

export enum FileSystemEvent {
  Created = 'Created',
  Deleted = 'Deleted',
  Changed = 'Changed',
}

export enum ImpactCategory {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum ImpactMultipliedBy {
  NotMultiplied = 'NotMultiplied',
  RobotHour = 'RobotHour',
  ItemManualProcessing = 'ItemManualProcessing',
}

export enum IncidentCategory {
  Alert = 'Alert',
  Anomaly = 'Anomaly',
  Forecasting = 'Forecasting',
  NegativeTrend = 'NegativeTrend',
}

export enum IncidentFeedback {
  None = 'None',
  Positive = 'Positive',
  Negative = 'Negative',
}

export enum IncidentPartType {
  BarChart = 'BarChart',
  Impact = 'Impact',
  MetricTimeSeries = 'MetricTimeSeries',
  MetricBreakdown = 'MetricBreakdown',
  MetricPerOwners = 'MetricPerOwners',
  MetricScatterPlot = 'MetricScatterPlot',
  MostIncreasingExceptions = 'MostIncreasingExceptions',
  Text = 'Text',
  TextList = 'TextList',
  TimeSeries = 'TimeSeries',
  TopExceptions = 'TopExceptions',
  TopExceptionsTrend = 'TopExceptionsTrend',
  UtilizationHeatmap = 'UtilizationHeatmap',
}

export enum IncidentEventType {
  CopyLink = 'CopyLink',
  ThumbUp = 'ThumbUp',
  ThumbDown = 'ThumbDown',
  OpenDashboard = 'OpenDashboard',
}

export enum IntervalType {
  Month = 'Month',
  Year = 'Year',
}

export enum LoginType {
  Password = 'Password',
  Microsoft = 'Microsoft',
}

export enum ManagementMetricType {
  SavingsMoney = 'SavingsMoney',
  SavingsHoursBackToBusiness = 'SavingsHoursBackToBusiness',
  SavingsFte = 'SavingsFte',
  TasksProcessed = 'TasksProcessed',
  NumberOfProcesses = 'NumberOfProcesses',
  TasksAverageSuccessRate = 'TasksAverageSuccessRate',
  MostExpensiveProcesses = 'MostExpensiveProcesses',
  RobotHours = 'RobotHours',
  SystemExceptionCosts = 'SystemExceptionCosts',
  BusinessExceptionCosts = 'BusinessExceptionCosts',
  TopExceptions = 'TopExceptions',
  LicenseUtilization = 'LicenseUtilization',
}

export const ManagementMetricTypeDescription = new Map<string, string>([
  [ManagementMetricType.SavingsMoney, 'Monetary savings'],
  [ManagementMetricType.SavingsHoursBackToBusiness, 'Hours back to business'],
  [ManagementMetricType.SavingsFte, 'Saved workload (FTE)'],
  [ManagementMetricType.TasksProcessed, 'Tasks processed'],
  [ManagementMetricType.NumberOfProcesses, 'Number of processes ran'],
  [ManagementMetricType.TasksAverageSuccessRate, 'Success rate'],
  [ManagementMetricType.MostExpensiveProcesses, 'Processes generating the greatest savings'],
  [ManagementMetricType.RobotHours, 'Robot-hours spent'],
  [ManagementMetricType.SystemExceptionCosts, 'Total cost of system exceptions'],
  [ManagementMetricType.BusinessExceptionCosts, 'Total cost of business exceptions'],
  [ManagementMetricType.TopExceptions, 'Costly exceptions'],
  [ManagementMetricType.LicenseUtilization, 'License utilization'],
]);

export enum ManagementMetricsAggregationType {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum Marking {
  Success = 'Success',
  Exception = 'Exception',
}

export enum MasterProcessStatus {
  Created = 'Created',
  Uploaded = 'Uploaded',
  Split = 'Split',
  Transformed = 'Transformed',
  Successful = 'Successful',
  Failed = 'Failed',
  Deleted = 'Deleted',
  Canceled = 'Canceled',
}

export enum MetricAggregationFunc {
  Sum = 'Sum',
  Average = 'Average',
  Median = 'Median',
}

export enum MetricBreakdownType {
  PerResource = 'PerResource',
  PerWeekDay = 'PerWeekDay',
  TimeOfDay = 'TimeOfDay',
}

export enum MetricValueType {
  Number = 'Number',
  Decimal = 'Decimal',
  Percentage = 'Percentage',
  Duration = 'Duration',
}

export enum MetricRuleTimeInterval {
  Last5Minutes = 'Last5Minutes',
  Last15Minutes = 'Last15Minutes',
  LastHour = 'LastHour',
  Last4Hours = 'Last4Hours',
  Last12Hours = 'Last12Hours',
  LastDay = 'LastDay',
}

export enum MetricSourceType {
  Queue = 'Queue',
  Process = 'Process',
  ProcessGroup = 'ProcessGroup',
}

export enum MonitoringMetricFrequency {
  Minutes5 = 'Minutes5',
  Minutes15 = 'Minutes15',
  Hourly = 'Hourly',
  Daily = 'Daily',
}

export enum MonitoringMetricType {
  ItemCount = 'ItemCount',
  ItemSystemExceptionCount = 'ItemSystemExceptionCount',
  ItemBusinessExceptionCount = 'ItemBusinessExceptionCount',
  ItemCompletedCount = 'ItemCompletedCount',
  ItemBusinessExceptionRate = 'ItemBusinessExceptionRate',
  ItemSuccessRate = 'ItemSuccessRate',
  ItemAverageDuration = 'ItemAverageDuration',
  ItemSuccessAverageDuration = 'ItemSuccessAverageDuration',
  ItemCompletedAverageDuration = 'ItemCompletedAverageDuration',
  ItemSystemExceptionAverageDuration = 'ItemSystemExceptionAverageDuration',
  ItemBusinessExceptionAverageDuration = 'ItemBusinessExceptionAverageDuration',
  ItemNotStartedCount = 'ItemNotStartedCount',
  ItemPendingCount = 'ItemPendingCount',
  ItemViolatedSlaCount = 'ItemViolatedSlaCount',
  ItemPendingMaxTime = 'ItemPendingMaxTime',
  ItemPendingAverageTime = 'ItemPendingAverageTime',
  ItemFteExpectedSavings = 'ItemFteExpectedSavings',
  ItemManualProcessingTimeInMinutesSavings = 'ItemManualProcessingTimeInMinutesSavings',
  SessionLoggingRate = 'SessionLoggingRate',
  SessionLogsSize = 'SessionLogsSize',
  SessionCount = 'SessionCount',
  SessionSystemExceptionCount = 'SessionSystemExceptionCount',
  SessionBusinessExceptionCount = 'SessionBusinessExceptionCount',
  SessionCompletedCount = 'SessionCompletedCount',
  SessionSuccessRate = 'SessionSuccessRate',
  SessionAverageDuration = 'SessionAverageDuration',
  SessionCompletedAverageDuration = 'SessionCompletedAverageDuration',
  SessionSuccessAverageDuration = 'SessionSuccessAverageDuration',
  SessionSystemExceptionAverageDuration = 'SessionSystemExceptionAverageDuration',
  SessionBusinessExceptionAverageDuration = 'SessionBusinessExceptionAverageDuration',
  SessionResourceUtilization = 'SessionResourceUtilization',
  SessionLicenseUtilization = 'SessionLicenseUtilization',
  ProcessCount = 'ProcessCount',
  ResourceCount = 'ResourceCount',
  LicenseCount = 'LicenseCount',
}

export const MonitoringMetricTypeDescription = new Map<string, string>([
  [MonitoringMetricType.ItemCount, 'Item count'],
  [MonitoringMetricType.ItemSystemExceptionCount, 'Item system exceptions'],
  [MonitoringMetricType.ItemBusinessExceptionCount, 'Item business exceptions'],
  [MonitoringMetricType.ItemCompletedCount, 'Item completed'],
  [MonitoringMetricType.ItemBusinessExceptionRate, 'Item business exception rate'],
  [MonitoringMetricType.ItemSuccessRate, 'Item success rate'],
  [MonitoringMetricType.ItemAverageDuration, 'Item average duration'],
  [MonitoringMetricType.ItemSuccessAverageDuration, 'Item success average duration'],
  [MonitoringMetricType.ItemCompletedAverageDuration, 'Item completed average duration'],
  [MonitoringMetricType.ItemSystemExceptionAverageDuration, 'Item system exceptions average duration'],
  [MonitoringMetricType.ItemBusinessExceptionAverageDuration, 'Item business exceptions average duration'],
  [MonitoringMetricType.ItemNotStartedCount, 'Item not started'],
  [MonitoringMetricType.ItemPendingCount, 'Item pendings'],
  [MonitoringMetricType.ItemViolatedSlaCount, 'Item violated SLA'],
  [MonitoringMetricType.ItemPendingMaxTime, 'Item pending max'],
  [MonitoringMetricType.ItemPendingAverageTime, 'Item pending avg'],
  [MonitoringMetricType.ItemFteExpectedSavings, 'Item FTE savings'],
  [MonitoringMetricType.ItemManualProcessingTimeInMinutesSavings, 'Item manual processing time savings'],
  [MonitoringMetricType.SessionLoggingRate, 'Session logging rate'],
  [MonitoringMetricType.SessionLogsSize, 'Session logs size'],
  [MonitoringMetricType.SessionCount, 'Session count'],
  [MonitoringMetricType.SessionSystemExceptionCount, 'Session system exceptions'],
  [MonitoringMetricType.SessionBusinessExceptionCount, 'Session business exceptions'],
  [MonitoringMetricType.SessionCompletedCount, 'Session completed count'],
  [MonitoringMetricType.SessionSuccessRate, 'Session success rate'],
  [MonitoringMetricType.SessionAverageDuration, 'Session average duration'],
  [MonitoringMetricType.SessionCompletedAverageDuration, 'Session completed average duration'],
  [MonitoringMetricType.SessionSuccessAverageDuration, 'Session success average duration'],
  [MonitoringMetricType.SessionSystemExceptionAverageDuration, 'Session system exceptions average duration'],
  [MonitoringMetricType.SessionBusinessExceptionAverageDuration, 'Session business exceptions average duration'],
  [MonitoringMetricType.SessionResourceUtilization, 'Session resource utilization'],
  [MonitoringMetricType.SessionLicenseUtilization, 'Session license utilization'],
  [MonitoringMetricType.ProcessCount, 'Process count'],
  [MonitoringMetricType.ResourceCount, 'Resource count'],
  [MonitoringMetricType.LicenseCount, 'License count'],
]);

export enum MonitoringResourceStatus {
  Offline = 'Offline',
  Working = 'Working',
  Pending = 'Pending',
  Available = 'Available',
  NotResponding = 'NotResponding',
  LoggedOut = 'LoggedOut',
  Private = 'Private',
}

export enum NotificationType {
  ProcessNotStarted = 'ProcessNotStarted',
  ResourceRestarted = 'ResourceRestarted',
  ResourceLoginFailed = 'ResourceLoginFailed',
  ResourceLogoutFailed = 'ResourceLogoutFailed',
  Custom = 'Custom',
}

export enum Permission {
  Monitoring = 'Monitoring',
  Orchestration = 'Orchestration',
  Simulator = 'Simulator',
  Analytics = 'Analytics',
  RuntimeAnalysis = 'RuntimeAnalysis',
  Reporting = 'Reporting',
  ProcessReporting = 'ProcessReporting',
  ExecutiveOverview = 'ExecutiveOverview',
  AllDomains = 'AllDomains',
  OrganizationSettings = 'OrganizationSettings',
  SystemAdmin = 'SystemAdmin',
  Benchmarking = 'Benchmarking',
}

export enum Platform {
  BluePrism = 'BluePrism',
  UiPath = 'UiPath',
  AutomationAnywhere = 'AutomationAnywhere',
  PowerAutomate = 'PowerAutomate',
}

export enum ProcessPriority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum ProcessScheduleState {
  StartedSuccessfully = 'StartedSuccessfully',
  FailingToStart = 'FailingToStart',
  FailedToStart = 'FailedToStart',
}

export enum Role {
  OrganizationAdministrator = 'OrganizationAdministrator',
  AutomationTeam = 'AutomationTeam',
  ProcessOwner = 'ProcessOwner',
}

export const RoleDescription = new Map<string, string>([
  [Role.OrganizationAdministrator, 'Organization admin'],
  [Role.AutomationTeam, 'Automation team'],
  [Role.ProcessOwner, 'Process owner'],
]);

export enum ResourceLoginType {
  NoLogin = 'NoLogin',
  ResourceLogin = 'ResourceLogin',
  ProcessLogin = 'ProcessLogin',
}

export enum ResourceSortBy {
  Label = 'Label',
  ProcessLabel = 'ProcessLabel',
  LastActive = 'LastActive',
  Status = 'Status',
  IsEnabled = 'IsEnabled',
}

export enum SavingsUnit {
  FTE = 'FTE',
  Hours = 'Hours',
}

export enum SessionParameterType {
  Date = 'Date',
  DateTime = 'DateTime',
  Flag = 'Flag',
  Number = 'Number',
  Password = 'Password',
  Text = 'Text',
  Time = 'Time',
  TimeSpan = 'TimeSpan',
}

export enum SessionStatus {
  Pending = 'Pending',
  Running = 'Running',
  Terminated = 'Terminated',
  Stopped = 'Stopped',
  Completed = 'Completed',
  Debugging = 'Debugging',
  Archived = 'Archived',
  Stopping = 'Stopping',
  Warning = 'Warning',
  Failed = 'Failed',
  NotResponding = 'NotResponding',
}

export enum SessionFinishedStatus {
  Exception = 'Exception',
  Any = 'Any',
  Success = 'Success',
  BusinessException = 'BusinessException',
  SystemException = 'SystemException',
  UnexpectedInterruption = 'UnexpectedInterruption',
}

export enum SchedulerConditionStateType {
  File = 'File',
  Directory = 'Directory',
  Webhook = 'Webhook',
}

export enum SchedulerResourceUnavailabilityReason {
  Unknown = 'Unknown',
  NotFoundInRpaSystem = 'NotFoundInRpaSystem',
  Offline = 'Offline',
  NotResponding = 'NotResponding',
  SameResourceConditionNotMet = 'SameResourceConditionNotMet',
  Disabled = 'Disabled',
  Hidden = 'Hidden',
  Reserved = 'Reserved',
  Working = 'Working',
  LoggedOut = 'LoggedOut',
  Private = 'Private',
  Available = 'Available',
}

export enum SchedulerTraceAction {
  StartSession = 'StartSession',
  RequestStopSession = 'RequestStopSession',
  ManualRequestStopSession = 'ManualRequestStopSession',
  StartShell = 'StartShell',
  SetTasksIsActive = 'SetTasksIsActive',
  BeginRestartResource = 'BeginRestartResource',
  EndRestartResource = 'EndRestartResource',
  DeletePendingSession = 'DeletePendingSession',
  LoginResource = 'LoginResource',
  LogoutResource = 'LogoutResource',
}

export enum SchedulerTraceState {
  ActionSuccessful = 'ActionSuccessful',
  ActionFailed = 'ActionFailed',
  ConditionEvaluationFailed = 'ConditionEvaluationFailed',
  ConditionStateChanged = 'ConditionStateChanged',
}

export enum SchedulerTraceReason {
  NoFreeLicenses = 'NoFreeLicenses',
  NoFreeResources = 'NoFreeResources',
  EmptyResourceGroup = 'EmptyResourceGroup',
  LocksAreUsed = 'LocksAreUsed',
  ConditionEvaluationError = 'ConditionEvaluationError',
  UnexpectedError = 'UnexpectedError',
  Timeout = 'Timeout',
}

export enum SchedulerTaskConfigurationCondition {
  Regularly = 'Regularly',
  Once = 'Once',
  QueueNotEmpty = 'QueueNotEmpty',
  ProcessFinished = 'ProcessFinished',
  FileSystem = 'FileSystem',
  Webhook = 'Webhook',
  Code = 'Code',
}

export enum SchedulerTaskConfigurationDateCondition {
  EveryDay = 'EveryDay',
  WeekDays = 'WeekDays',
  Weekends = 'Weekends',
  DaysOfWeek = 'DaysOfWeek',
  DaysOfMonth = 'DaysOfMonth',
  DayOfWeekOfMonth = 'DayOfWeekOfMonth',
}

export enum SchedulerTaskConfigurationFileSystemCondition {
  File = 'File',
  Directory = 'Directory',
}

export enum SchedulerTaskConfigurationTimeCondition {
  Times = 'Times',
  TimeWindows = 'TimeWindows',
}

export enum TaskExceptionSourceType {
  Process = 'Process',
  Queue = 'Queue',
}

export enum TopExceptionsTrendType {
  CountOverDays = 'CountOverDays',
  RateOverDayHours = 'RateOverDayHours',
}

export enum SchedulerConditionType {
  CSharp = 'CSharp',
  DateTime = 'DateTime',
  DayOfWeekOfMonth = 'DayOfWeekOfMonth',
  DaysOfMonth = 'DaysOfMonth',
  DaysOfWeek = 'DaysOfWeek',
  Directory = 'Directory',
  EmptyQueue = 'EmptyQueue',
  File = 'File',
  IsNotHoliday = 'IsNotHoliday',
  IsWeekDay = 'IsWeekDay',
  IsWeekend = 'IsWeekend',
  Months = 'Months',
  OnceADay = 'OnceADay',
  OnceAPeriod = 'OnceAPeriod',
  OnceAWindow = 'OnceAWindow',
  ProcessFinished = 'ProcessFinished',
  Queue = 'Queue',
  StartInTimeWindow = 'StartInTimeWindow',
  TaskFinished = 'TaskFinished',
  Time = 'Time',
  TimeWindow = 'TimeWindow',
  Webhook = 'Webhook',
}

